import { createSlice } from "@reduxjs/toolkit";
import { getAppData } from "../../lib/api";
import { initialLanguage } from "../../lib/config";
import { getCollectionWithTranslatedFields } from "../../lib/statamic";
import { setAssets } from "./graph";

const { actions, reducer } = createSlice({
  name: "general",
  initialState: {
    language: initialLanguage || "de",
    idleMode: false,
    data: [],
    dataLoadingState: "idle",
  },
  reducers: {
    setLanguage(state, action) {
      state.language = action.payload;
    },
    enterIdleMode(state) {
      state.idleMode = true;
    },
    exitIdleMode(state) {
      state.idleMode = false;
    },
    dataLoading(state) {
      state.dataLoadingState = "pending";
    },
    dataReceived(state, action) {
      if (state.dataLoadingState === "pending") {
        state.dataLoadingState = "idle";
        state.data = action.payload;
      }
    },
  },
});

export const {
  setLanguage,
  enterIdleMode,
  exitIdleMode,
  dataLoading,
  dataReceived,
} = actions;

export const fetchData = () => async (dispatch) => {
  dispatch(dataLoading());
  const data = await getAppData();

  const assetData = data.data.items.data.filter((item) => item);
  const assets = getCollectionWithTranslatedFields(assetData, [
    "file",
    "caption",
    "description",
  ]);

  assets.forEach((asset) => (asset.asset_type = asset.asset_type.value));

  dispatch(setAssets(assets));
  dispatch(dataReceived(data.data));
};

export default reducer;
