import tailwindConfig from "../../tailwind.config";

const { colors } = tailwindConfig.theme;

export const getTextGradientStyle = () => {
  const gradient = `linear-gradient(90deg, ${colors.beta}, ${colors.alpha}, ${colors.beta}, ${colors.alpha})`;

  return {
    backgroundImage: gradient,
    WebkitTextFillColor: "transparent",
    WebkitBackgroundClip: "text",
    backgroundClip: "text",
  };
};
