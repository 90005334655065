import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import config from "../../lib/config";
import { getMaxDimensions } from "../../lib/helpers";
import { getFullAssetUrl } from "../../lib/statamic";
import { selectFullscreenElement } from "../../state/selectors/fullscreen";
import { selectIdleMode } from "../../state/selectors/general";
import { closeFullscreen } from "../../state/slices/fullscreen";
import FullscreenContainer from "./FullscreenContainer";
import FullscreenElement from "./FullscreenElement";

export default function Fullscreen() {
  const dispatch = useDispatch();
  const fullscreenElement = useSelector(selectFullscreenElement);
  const fullscreenOpen = fullscreenElement.type;
  const idleMode = useSelector(selectIdleMode);

  const close = () => {
    dispatch(closeFullscreen());
  };

  const closeFullscreenOnIdleMode = () => {
    if (!fullscreenOpen || !idleMode) return;

    close();
  };

  const initEscListener = () => {
    const handleKeyPress = (event) => {
      if (event.key === "Escape") close();
    };

    document.addEventListener("keydown", handleKeyPress);
    return () => document.removeEventListener("keydown", handleKeyPress);
  };

  useEffect(closeFullscreenOnIdleMode, [idleMode]);
  useEffect(initEscListener, []);

  if (!fullscreenOpen) return null;

  const padding = 0.2;
  const { width: containerWidth, height: containerHeight } = config;
  const { file, caption, description } = fullscreenElement;
  const { width: srcWidth, height: srcHeight, url } = file;
  const src = getFullAssetUrl(url);

  const renderImage = () => {
    const { width, height } = getMaxDimensions({
      srcWidth,
      srcHeight,
      targetWidth: containerWidth * (1 - padding),
      targetHeight: containerHeight * (1 - padding),
    });

    return (
      <FullscreenElement
        width={width}
        height={height}
        caption={caption}
        onCloseButtonClick={close}
      >
        <img className="w-full h-full" src={src} />
      </FullscreenElement>
    );
  };

  const renderKeyword = () => {
    return (
      <FullscreenElement
        width={containerWidth * 0.5}
        caption={caption}
        onCloseButtonClick={close}
      >
        <div className="w-full h-full p-12">
          <img style={{ height: "120px" }} src={src} />
          {description && (
            <div
              dangerouslySetInnerHTML={{ __html: description }}
              className="text-beta mt-8"
            />
          )}
        </div>
      </FullscreenElement>
    );
  };

  return (
    <FullscreenContainer>
      {fullscreenElement.type === "image" && renderImage()}
      {fullscreenElement.type === "keyword" && renderKeyword()}
    </FullscreenContainer>
  );
}
