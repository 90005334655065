import { createSlice } from "@reduxjs/toolkit";

const { actions, reducer } = createSlice({
  name: "mqtt",
  initialState: {
    connected: false,
    subscribed: false,
    lastMessageReceived: {
      property: null,
      value: null,
    },
    lastMessagePublished: {
      property: null,
      value: null,
    },
  },
  reducers: {
    setConnected(state) {
      state.connected = true;
    },
    setDisconnected(state) {
      state.connected = false;
    },
    setSubscribed(state) {
      state.subscribed = true;
    },
    setUnsubscribed(state) {
      state.subscribed = false;
    },
    receivedMessage(state, payload) {
      state.lastMessageReceived = payload;
    },
    publishedMessage(state, payload) {
      state.lastMessagePublished = payload;
    },
  },
});

export const {
  setConnected,
  setDisconnected,
  setSubscribed,
  setUnsubscribed,
  receivedMessage,
  publishedMessage,
} = actions;

export default reducer;
