import { useSelector } from "react-redux";
import tailwindConfig from "../../tailwind.config";
import { selectDataLoadingState } from "../state/selectors/general";
import Fullscreen from "./Fullscreen/Index";
import LoadingScreen from "./Loading/LoadingScreen";
import Graph from "./Graph";
import LanguageButton from "./LanguageButton";
import Logo from "./Logo";

export default function App() {
  const dataLoadingState = useSelector(selectDataLoadingState);
  const { backgroundColor, white } = tailwindConfig.theme.colors;

  return (
    <>
      {dataLoadingState === "pending" ? (
        <LoadingScreen backgroundColor={backgroundColor} color={white} />
      ) : (
        <>
          <Graph />
          <Logo />
          <LanguageButton />
          <Fullscreen />
        </>
      )}
    </>
  );
}
