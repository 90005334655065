import tailwindConfig from "../../../tailwind.config";

const { colors } = tailwindConfig.theme;

export const Gradient = ({ id }) => (
  <linearGradient id={id} x1="0%" y1="0%" x2="100%" y2="0%">
    <stop offset="0%" style={{ stopColor: colors.alpha, stopOpacity: 1 }} />
    <stop offset="33%" style={{ stopColor: colors.beta, stopOpacity: 1 }} />
    <stop offset="66%" style={{ stopColor: colors.alpha, stopOpacity: 1 }} />
    <stop offset="100%" style={{ stopColor: colors.beta, stopOpacity: 1 }} />
  </linearGradient>
);
