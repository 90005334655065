import { configureStore } from "@reduxjs/toolkit";
import fullscreen from "./slices/fullscreen";
import general from "./slices/general";
import graph from "./slices/graph";
import mqtt from "./slices/mqtt";

export const store = configureStore({
  reducer: { general, mqtt, graph, fullscreen },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(),
});
