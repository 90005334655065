import { Gradient } from "./Gradient";

export const Close = ({ className }) => {
  const gradientId = "grad-close";

  return (
    <svg viewBox="0 0 1000 1000" className={className}>
      <defs>
        <Gradient id={gradientId} />
      </defs>
      <g>
        <path
          fill={`url(#${gradientId})`}
          d="M0,112.91L112.91,0l306.45,306.45L306.45,419.36L0,112.91z M0,887.09l306.45-306.45l112.91,112.91L112.91,1000
		L0,887.09z M580.64,306.45L887.09,0L1000,112.91L693.55,419.36L580.64,306.45z M580.64,693.55l112.91-112.91L1000,887.09
		L887.09,1000L580.64,693.55z"
        />
      </g>
    </svg>
  );
};
