import { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getRandom } from "../lib/helpers";
import {
  selectRecruitingFrequency,
  selectUpdateTimeout,
} from "../state/selectors/general";
import {
  selectAssetById,
  selectAssetNodeByIndex,
  selectAssetNodes,
  selectLineCoordinates,
  selectRandomAssets,
  selectRandomNewAssets,
} from "../state/selectors/graph";
import {
  createAssetNodes,
  hideAssetNode,
  randomlyUpdateLineCoordinates,
  relocateAssetNodes,
  showAssetNode,
  updateAssetNodeAsset,
} from "../state/slices/graph";
import { store } from "../state/store";
import AssetElement from "./AssetElement";
import Line from "./Line";

const ASSET_TYPES = ["keyword", "photo", "graphic", "screenshot", "recruiting"];

export default function Graph() {
  const dispatch = useDispatch();
  const removedCategory = useRef();
  const updatesWithoutRecruitingAsset = useRef(0);
  const updateTimeout = useSelector(selectUpdateTimeout);
  const assetNodes = useSelector(selectAssetNodes);
  const recruitingFrequency = useSelector(selectRecruitingFrequency);
  const lineCoordinates = useSelector(selectLineCoordinates);

  const init = () => {
    const state = store.getState();

    // TODO: one more asset in selection

    // get a random selection of assets
    const initalSelection = [
      ...selectRandomAssets(state, 2, ASSET_TYPES[0]),
      ...selectRandomAssets(state, 2, ASSET_TYPES[1]),
      ...selectRandomAssets(state, 2, ASSET_TYPES[2]),
      ...selectRandomAssets(state, 2, ASSET_TYPES[3]),
    ];

    const nodes = initalSelection.map((asset) => ({ assetId: asset.id }));

    dispatch(createAssetNodes(nodes));
    dispatch(relocateAssetNodes());
    dispatch(randomlyUpdateLineCoordinates());
  };

  const setUpdateTimer = () => {
    const timeout = setTimeout(() => {
      const state = store.getState();
      const assetNodes = selectAssetNodes(state);

      // select a random asset node
      const index = parseInt(getRandom(0, assetNodes.length));
      const node = selectAssetNodeByIndex(state, index);
      const nodeAsset = selectAssetById(state, node.assetId);

      /**
       * every x update cycle there should be an asset of type "recruiting".
       * we are counting cycles without that type.
       * if the count reaches the configured frequency the next update should
       * contain one and the counter is reseted.
       * if we update one node with an recruiting asset, the initial spread of categories
       * is getting out of order, so we memorize the removed asset type until the
       * recruiting asset is removed again and we can insert an asset of the memorized
       * category
       */
      const currentSelctionHasRecruitingAsset =
        assetNodes.find((node) => {
          const asset = selectAssetById(state, node.assetId);

          return asset.asset_type === ASSET_TYPES[4];
        }) !== undefined;

      if (!currentSelctionHasRecruitingAsset) {
        updatesWithoutRecruitingAsset.current++;
      } else {
        updatesWithoutRecruitingAsset.current = 0;
      }

      const shouldGetRecruitingAsset =
        updatesWithoutRecruitingAsset.current >= recruitingFrequency;

      let newAsset;

      if (shouldGetRecruitingAsset) {
        removedCategory.current = nodeAsset.asset_type;
        newAsset = selectRandomNewAssets(state, 1, ASSET_TYPES[4])[0];
      } else {
        let newType;

        if (nodeAsset.asset_type === ASSET_TYPES[4]) {
          newType = removedCategory.current;
          removedCategory.current = null;
        } else {
          newType = nodeAsset.asset_type;
        }

        newAsset = selectRandomNewAssets(state, 1, newType)[0];
      }
      // --------------

      const assetId = newAsset.id;

      dispatch(hideAssetNode(index));

      setTimeout(() => {
        // update random asset node with the new asset
        dispatch(updateAssetNodeAsset({ nodeIndex: index, assetId }));
      }, 1500);

      setTimeout(() => {
        dispatch(showAssetNode(index));
      }, 1700);

      setTimeout(() => {
        dispatch(relocateAssetNodes());
        dispatch(randomlyUpdateLineCoordinates());
      }, 2000);
    }, updateTimeout * 1000);

    return () => clearTimeout(timeout);
  };

  useEffect(setUpdateTimer, [assetNodes]);
  useEffect(init, []);

  return (
    <div className="w-full h-full bg-backgroundColor">
      {lineCoordinates && <Line points={lineCoordinates} />}
      {assetNodes.map((_, index) => (
        <AssetElement key={index} nodeIndex={index} />
      ))}
    </div>
  );
}
