import { createSelector } from "@reduxjs/toolkit";

export const selectData = (state) => state.general.data;
export const selectDataLoadingState = (state) => state.general.dataLoadingState;
export const selectLanguage = (state) => state.general.language;
export const selectIdleMode = (state) => state.general.idleMode;
export const selectIdleTimeout = createSelector(
  selectData,
  (data) => data.general.data[0].idle_timeout,
);
export const selectUpdateTimeout = createSelector(
  selectData,
  (data) => data.general.data[0].update_timeout,
);
export const selectRecruitingFrequency = createSelector(
  selectData,
  (data) => data.general.data[0].recruiting_frequency,
);
