import { useDispatch, useSelector } from "react-redux";
import { motion } from "framer-motion";
import { getFullAssetUrl } from "../lib/statamic";
import { selectLanguage } from "../state/selectors/general";
import {
  selectAssetById,
  selectAssetNodeByIndex,
} from "../state/selectors/graph";
import { setFullscreenElement } from "../state/slices/fullscreen";

export default function AssetElement({ nodeIndex }) {
  const dispatch = useDispatch();
  const node = useSelector((state) => selectAssetNodeByIndex(state, nodeIndex));
  const asset = useSelector((state) => selectAssetById(state, node.assetId));
  const language = useSelector(selectLanguage);
  const file = asset.file[language];
  const type = asset.asset_type;
  const caption = asset.caption[language];
  const description = asset.description[language];
  const src = getFullAssetUrl(file.url);
  const { x, y, width, height, show } = node;

  const transition = { ease: "easeInOut", duration: 1.5 };

  const variants = {
    initial: {
      opacity: 0,
    },
    animate: {
      x,
      y,
      opacity: show ? 1 : 0,
      transition: { ...transition, delay: show ? nodeIndex * 0.08 : 0 },
    },
    exit: {
      opacity: 0,
      transition,
    },
  };

  const handleClick = () => {
    dispatch(
      setFullscreenElement({
        type: type === "keyword" ? "keyword" : "image",
        caption,
        description,
        file,
      }),
    );
  };

  return (
    <motion.div
      initial="initial"
      exit="exit"
      animate="animate"
      variants={variants}
      style={{
        position: "absolute",
        width,
        height,
      }}
      onClick={handleClick}
    >
      <img src={src} width={width} height={height} />
    </motion.div>
  );
}
