import { useEffect, useState } from "react";

export default function AutoscaleLayout({
  width,
  height,
  className,
  children,
}) {
  const [scale, setScale] = useState(1);
  const aspectRatio = width / height;

  const calculateScale = () => {
    let scaleFactor;
    const windowWidth = window.innerWidth;
    const windowHeight = window.innerHeight;
    const windowAspectRatio = windowWidth / windowHeight;

    if (windowAspectRatio > aspectRatio) {
      scaleFactor = windowHeight / height;
    } else {
      scaleFactor = windowWidth / width;
    }

    const scaleFactorRounded = Math.round(scaleFactor * 10000) / 10000;

    setScale(scaleFactorRounded);
  };

  const initResizeEventHandler = () => {
    window.addEventListener("resize", calculateScale);
    return () => window.removeEventListener("resize", calculateScale);
  };

  useEffect(calculateScale, []);
  useEffect(initResizeEventHandler, []);

  return (
    <div
      className={className}
      style={{
        backgroundColor: "white",
        position: "absolute",
        top: "50%",
        left: "50%",
        width: `${width}px`,
        height: `${height}px`,
        transform: `translate(-50%, -50%) scale(${scale})`,
        transformOrigin: "center center",
        overflow: "hidden",
      }}
    >
      {children}
    </div>
  );
}
