import { useEffect } from "react";
import { useRef } from "react";
import { animate } from "framer-motion";
import tailwindConfig from "../../tailwind.config";
import config from "../lib/config";

const { colors } = tailwindConfig.theme;

export default function Line({ points: pointsNested }) {
  const { width, height } = config;
  const points = pointsNested.flat();
  const pointsAnimated = useRef(points);
  const polylineRef = useRef();

  const animationConfig = {
    duration: 1.5,
    ease: "easeInOut",
  };

  const createPointsString = (array) =>
    array
      .map((_, i) => (i % 2 === 0 ? `${array[i]},${array[i + 1]}` : " "))
      .join("");

  const onTick = (value, index) => {
    pointsAnimated.current[index] = value;
    const pointsString = createPointsString(pointsAnimated.current);
    polylineRef.current.setAttribute("points", pointsString);
  };

  const animatePolyline = () => {
    points.map((pointNewValue, index) => {
      const pointCurrentValue = pointsAnimated.current[index];

      animate(pointCurrentValue, pointNewValue, {
        ...animationConfig,
        onUpdate: (value) => onTick(value, index),
      });
    });
  };

  useEffect(animatePolyline, [points]);

  return (
    <svg viewBox={`0 0 ${width} ${height}`} className="absolute">
      <polyline
        ref={polylineRef}
        stroke={colors.beta}
        fill="none"
        strokeWidth="70"
      />
    </svg>
  );
}
