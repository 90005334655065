import { createSelector } from "@reduxjs/toolkit";
import { getRandomElements, getSelectionDiff } from "../../lib/helpers";

export const selectAssets = (state) => state.graph.assets;
export const selectAssetNodes = (state) => state.graph.assetNodes;
export const selectLineCoordinates = (state) => state.graph.lineCoordinates;

export const selectAssetById = createSelector(
  selectAssets,
  (_, id) => id,
  (assets, id) => assets.find((a) => a.id === id),
);

export const selectAssetNodeByIndex = createSelector(
  selectAssetNodes,
  (_, index) => index,
  (nodes, index) => nodes[index],
);

/**
 * selects random assets of specified types
 */
export const selectRandomAssets = (state, count, types = []) => {
  const assets = selectAssets(state);
  const assetsOfType = assets.filter(
    (i) => types.length === 0 || types.includes(i.asset_type),
  );

  if (assetsOfType.length === 0) return [];

  return getRandomElements(assetsOfType, count);
};

/**
 * selects random assets of specified types that are not
 * in the current nodes selection
 */
export const selectRandomNewAssets = (state, count, types = []) => {
  const assets = selectAssets(state);
  const nodes = selectAssetNodes(state);
  const newAssets = getSelectionDiff(assets, nodes, {
    keyA: "id",
    keyB: "assetId",
  });

  let assetsOfType;
  const typeFilter = (i) => types.length === 0 || types.includes(i.asset_type);

  const newAssetsOfType = newAssets.filter(typeFilter);

  if (newAssetsOfType.length > 0) {
    assetsOfType = newAssetsOfType;
  } else {
    assetsOfType = assets.filter(typeFilter);
  }

  return getRandomElements(assetsOfType, count);
};
