import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  element: {
    type: null,
    url: null,
    caption: null,
    width: null,
    height: null,
  },
};

const { actions, reducer } = createSlice({
  name: "articles",
  initialState,
  reducers: {
    setFullscreenElement(state, action) {
      state.element = action.payload;
    },
    closeFullscreen(state) {
      state.element = initialState;
    },
  },
});

export const { setFullscreenElement, closeFullscreen } = actions;

export default reducer;
