import FetchRetry from "fetch-retry";
import config from "./config";

const fetchRetry = FetchRetry(fetch);

export const request = ({
  method = "GET",
  url,
  body,
  reqJson = true,
  resJson = true,
  retries = 0,
}) => {
  return new Promise((resolve, reject) => {
    fetchRetry(url, {
      retryOn: (attempt, error, response) => {
        return error !== null || response.status >= 400;
      },
      retryDelay: 5000,
      retries,
      method,
      headers: {
        "Content-Type": reqJson ? "application/json" : undefined,
      },
      body: reqJson ? JSON.stringify(body) : body,
    })
      .then((response) => {
        if (resJson) return response.json();
        return response;
      })
      .then((data) => resolve(data))
      .catch((error) => reject(error));
  });
};

export const getAppData = () => {
  return request({
    url: `${config.cmsHost}/api/data/${config.dataFetchSlug}`,
    retries: 9999,
  });
};

export const getMesoConnectConfig = () => {
  return request({ url: config.mesoConnectConfigUrl });
};
