import { createSlice } from "@reduxjs/toolkit";
import labelLocator from "label-locator";
import config from "../../lib/config";
import { getRandom } from "../../lib/helpers";
import { selectAssetById } from "../selectors/graph";

const { width: containerWidth, height: containerHeight } = config;

const locatePositions = (n) => {
  const logoAliasNode = {
    x: 0,
    y: 0,
    width: 300,
    height: 100,
    fixed: true,
  };

  const languageButtonAliasNode = {
    x: containerWidth - 80,
    y: 0,
    width: 80,
    height: 80,
    fixed: true,
  };

  const nodes = [...n, logoAliasNode, languageButtonAliasNode];

  const locator = labelLocator({
    labels: nodes,
    anchors: nodes,
    labelMargin: 30,
    containerWidth,
    containerHeight,
    maxDistance: 2000,
    preferredDistance: 300,
    onlyMoveOrthogonally: false,
    weights: {
      outOfBounds: Infinity,
      labelAnchorDistance: 0.1,
      labelAnchorOverlap: 0,
      labelOwnAnchorOverlap: 0,
      labelLabelOverlap: 3000,
    },
  });

  const newLabels = locator.start(2000);

  return newLabels.slice(0, nodes.length - 2);
};

const getRandomDimensions = (assetWidth, assetHeight, type) => {
  let width;
  let height;

  const dimensions = {
    min: containerWidth * 0.13,
    max: containerWidth * 0.2,
    keyword: {
      min: containerWidth * 0.1,
      max: containerWidth * 0.2,
    },
  };

  const orientationIsHorizontal = assetWidth > assetHeight;
  const aspectRatio = assetWidth / assetHeight;
  const min = type && dimensions[type] ? dimensions[type].min : dimensions.min;
  const max = type && dimensions[type] ? dimensions[type].max : dimensions.max;

  if (orientationIsHorizontal) {
    width = getRandom(min, max);
    height = width / aspectRatio;
  } else {
    height = getRandom(min, max);
    width = height * aspectRatio;
  }

  return { width, height };
};

const { actions, reducer } = createSlice({
  name: "graph",
  initialState: {
    assets: [],
    assetNodes: [],
    lineCoordinates: null,
  },
  reducers: {
    setAssets(state, action) {
      state.assets = action.payload;
    },
    setAssetNodes(state, action) {
      state.assetNodes = action.payload;
    },
    hideAssetNode(state, action) {
      state.assetNodes[action.payload].show = false;
    },
    showAssetNode(state, action) {
      state.assetNodes[action.payload].show = true;
    },
    updateAssetNode(state, action) {
      const { index, node } = action.payload;
      state.assetNodes[index] = node;
    },
    relocateAssetNodes(state) {
      state.assetNodes = locatePositions(state.assetNodes);
    },
    setLineCoordinates(state, action) {
      state.lineCoordinates = action.payload;
    },
  },
});

export const {
  setAssets,
  setAssetNodes,
  hideAssetNode,
  showAssetNode,
  updateAssetNode,
  relocateAssetNodes,
  setLineCoordinates,
} = actions;

export const createAssetNodes = (nodes) => (dispatch, getState) => {
  const state = getState();
  const language = state.general.language;

  const n = nodes.map(({ assetId }) => {
    const asset = selectAssetById(state, assetId);
    const assetWidth = asset.file[language].width;
    const assetHeight = asset.file[language].height;
    const type = asset.asset_type;
    const { width, height } = getRandomDimensions(
      assetWidth,
      assetHeight,
      type,
    );
    const x = getRandom(0, containerWidth);
    const y = getRandom(0, containerHeight);

    return {
      assetId,
      x,
      y,
      width,
      height,
      show: true,
    };
  });

  dispatch(setAssetNodes(n));
};

export const randomlyUpdateLineCoordinates = () => (dispatch) => {
  const paddingLeftRight = -50;
  const minY = containerHeight * 0.25;
  const maxY = containerHeight * 0.75;

  const point1minX = containerWidth * 0.2;
  const point1maxX = containerWidth * 0.4;
  const point2minX = containerWidth * 0.6;
  const point2maxX = containerWidth * 0.8;

  const newCoordinates = [
    [paddingLeftRight, getRandom(minY, maxY)],
    [getRandom(point1minX, point1maxX), getRandom(minY, maxY)],
    [getRandom(point2minX, point2maxX), getRandom(minY, maxY)],
    [containerWidth - paddingLeftRight, getRandom(minY, maxY)],
  ];

  dispatch(setLineCoordinates(newCoordinates));
};

export const updateAssetNodeAsset =
  ({ nodeIndex, assetId }) =>
  (dispatch, getState) => {
    const state = getState();
    const language = state.general.language;
    const node = state.graph.assetNodes[nodeIndex];

    const asset = selectAssetById(state, assetId);
    const assetWidth = asset.file[language].width;
    const assetHeight = asset.file[language].height;
    const type = asset.asset_type;
    const { width, height } = getRandomDimensions(
      assetWidth,
      assetHeight,
      type,
    );

    dispatch(
      updateAssetNode({
        index: nodeIndex,
        node: {
          ...node,
          assetId,
          width,
          height,
        },
      }),
    );
  };

export default reducer;
