import { Close } from "../GradientIcons/Close";

export default function FullscreenElement({
  width,
  height,
  children,
  caption,
  onCloseButtonClick,
}) {
  return (
    <div
      className="flex flex-col items-center text-black"
      style={{ width, height }}
    >
      <div className="w-full h-full bg-white">{children}</div>
      {caption && (
        <div style={{ width }} className="text-white text-xs mt-1">
          {caption}
        </div>
      )}
      <div
        className="absolute top-0 right-0 z-10 m-3"
        onClick={onCloseButtonClick}
      >
        <Close className="w-12" />
      </div>
    </div>
  );
}
