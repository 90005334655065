export const getRandom = (min, max) => {
  return Math.random() * (max - min) + min;
};

export const getRandomElements = (items, count) => {
  const elementsShuffled = items
    .map((i) => ({ sort: Math.random(), value: i }))
    .sort((a, b) => a.sort - b.sort)
    .map((i) => i.value);

  return elementsShuffled.slice(0, count);
};

export const getSelectionDiff = (
  selectionA,
  selectionB,
  { keyA = "id", keyB = "id" } = {},
) => {
  return selectionA.filter((a) => {
    return selectionB.findIndex((b) => a[keyA] === b[keyB]) === -1;
  });
};

export const getMaxDimensions = ({
  srcWidth,
  srcHeight,
  targetWidth,
  targetHeight,
}) => {
  const containerRatio = targetWidth / targetHeight;
  const srcRatio = srcWidth / srcHeight;

  const maxHeight = targetHeight;
  const maxWidth = targetWidth;

  let width, height;

  if (containerRatio < srcRatio) {
    width = maxWidth;
    height = srcHeight * (maxWidth / srcWidth);
  } else {
    height = maxHeight;
    width = srcWidth * (maxHeight / srcHeight);
  }

  return { width, height };
};

export const hexToRgb = (hex, alpha) => {
  if (!hex) return "rgb(0,0,0)";

  const r = parseInt(hex.slice(1, 3), 16);
  const g = parseInt(hex.slice(3, 5), 16);
  const b = parseInt(hex.slice(5, 7), 16);

  if (Number.isFinite(alpha)) {
    return "rgba(" + r + ", " + g + ", " + b + ", " + alpha + ")";
  } else {
    return "rgb(" + r + ", " + g + ", " + b + ")";
  }
};
