import { motion } from "framer-motion";
import { theme } from "../../../tailwind.config";
import { hexToRgb } from "../../lib/helpers";

const { colors } = theme;

export default function FullscreenOverlay({ children, duration = 0.5 }) {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ ease: "easeOut", duration }}
      className="absolute top-0 left-0 w-full h-full z-10 flex justify-center items-center p-10"
      style={{
        backgroundColor: hexToRgb(colors.backgroundColor, 0.9),
      }}
    >
      {children}
    </motion.div>
  );
}
