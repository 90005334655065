import Spinner from "./Spinner";

export default function LoadingScreen({
  backgroundColor = "white",
  color,
  spinnerWidth,
  spinnerHeight,
}) {
  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor,
      }}
    >
      <Spinner color={color} width={spinnerWidth} height={spinnerHeight} />
    </div>
  );
}
