import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import App from "./components/App";
import AutoscaleLayout from "./components/AutoscaleLayout";
import { getMesoConnectConfig } from "./lib/api";
import config from "./lib/config";
import { init as initIdle } from "./lib/idle";
import * as mqtt from "./lib/mqtt";
import { fetchData } from "./state/slices/general";
import { store } from "./state/store";
import "./styles/index.css";

initIdle();
store.dispatch(fetchData());

if (config.mqttEnabled) {
  getMesoConnectConfig().then((config) => mqtt.initConnection(config));
}

if (config.mode === "app") {
  const html = document.querySelector("html");

  // disbale text selection
  html.style.userSelect = "none";
}

ReactDOM.render(
  <Provider store={store}>
    <AutoscaleLayout width={config.width} height={config.height}>
      <App />
    </AutoscaleLayout>
  </Provider>,
  document.getElementById("app"),
);
